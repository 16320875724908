@import '../../core/scss/base/index';

@keyframes creditsAnimation {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.creditsWrapper {
  height: 100%;
  overflow: hidden;
  background: linear-gradient(to left, $midnightblue, $darkblue);
  position: relative;
}

.buttonWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba($darkblue, 0.5);
  width: 100%;
  padding: $spacingXS;

  a {
    text-align: center;
    font-size: $smallSize;
    margin: 0;
    padding: $spacingXXS;
    height: auto;
  }
}

.credits {
  height: 100%;
  margin: 0;
  overflow: hidden;
  color: $white;
  animation-name: creditsAnimation;
  animation-duration: 15s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

.app {
  font-size: 2 * $extraLargeSize;
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 120px;
}
.job {
  font-size: $extraLargeSize;
  color: $sushi;

  span {
    margin-left: $spacingS;
  }
}
.name {
  font-size: $largeSize;
}
