@import '../../../core/scss/base/index';

.accordion {
  padding: $spacingS;
  cursor: pointer;
  border: 1px solid $sky;
  background-color: $white;

  &:hover {
    background-color: $brightgrey;
  }
}

.open {
  border: 1px solid $cornflower;
  outline: 1px solid $cornflower;

  &:hover {
    background-color: $white;
  }
}

.disabled {
  cursor: default;
  color: #999;
  &:hover {
    background-color: $white !important;
  }
}

.container {
  margin-top: -1px;
  padding: $spacingS;
  background-color: rgba(200, 200, 200, 0.3);
  border: 1px solid $cornflower;
  outline: 1px solid $cornflower;
}

.wrapper {
  margin-bottom: $spacingM;
}