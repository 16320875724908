.story-page .container {
  height: 100%;
  position: relative;
  overflow: hidden;
  margin: 0;
}
@import '../../core/scss/base/index';

.story {
  height: 100%;
  background-color: $darkblue;
}

.wrapper {
  position: relative;
  height: calc(100% - 162px);
}

.overlay {
  background-color: yellow;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
