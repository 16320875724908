@import '../../../../../../core/scss/base/index';

.exerciseButton {
  font-size: $normalSize;
  padding: $spacingXXS;
  width: 200px;
  height: 35px;
  margin: 0;
}

.modal {
  position: absolute;
}

.exerciseBoard {
  height: 300px;
  background-color: $black;
}

.errorMessage {
  background-color: $black;
  color: $grey;
  text-align: center;
  min-height: 300px;
}

.cancelButton {
  background-color: $froly;
  border-color: $froly;
  width: 250px;
}
