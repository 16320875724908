@import '../../core/scss/base/index';

.reports {
  min-height: 100%;
  font-family: 'robotolight';
  text-transform: initial;
}

.list {
  width: 100%;
}

.item {
  padding: $spacingS;
  border-bottom: 1px solid $lightgrey;
  color: $darkgrey;

  &:first-child {
    background-color: $brightgrey;
  }
}
