@import '../../../../core/scss/base/index';

.mission {
  width: calc(100% - 200px);
  height: calc(100% - 60px);
  position: absolute;
  top: 60px;
  left: 200px;
  z-index: 1;
  overflow-y: auto;
}

.missionStarted {
  background-color: rgba($cornflower, 0.3);
}

.missionStatus {
  color: $sushi;
  font-size: $largeSize;
}
