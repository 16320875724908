@import '../../../core/scss/base/index';

.empty {
  color: $lightgrey;
  font-size: $extraLargeSize;
  letter-spacing: 0.2em;

  span {
    margin-left: $spacingS;
  }
}
