@import '../../core/scss/base/index';

.exerciseView {
  min-height: 100%;
  font-family: 'robotolight';
  text-transform: initial;
}

.redirectButton {
  font-size: $smallSize;
  color: $cornflower;
  padding: $spacingXXS;
  border: 1px solid $cornflower;
  text-decoration: none;

  span {
    margin-left: $spacingXXS;
  }

  &:hover {
    background-color: rgba($cornflower, 0.1);
  }
}

.label {
  background-color: $cornflower;
  color: $white;
  padding: $spacingXXS $spacingS;
  margin-left: $spacingXS;
  font-size: $smallSize;
}
