@import '../../../../../../core/scss/base/index';

.exerciseProblem {
  background-color: $black;

  & > div:first-child {
    min-height: 200px;
  }

  & > div {
    background-color: $black;
    border: none;
  }
}

.noExerciseMessage {
  background-color: $black;
  color: $grey;
  text-align: center;
  min-height: 300px;
}

.errorMessage {
  background-color: $black;
  color: $grey;
  text-align: center;
  min-height: 300px;
}

.loadingMessage {
  background-color: $black;
  color: $grey;
  text-align: center;
  min-height: 300px;
}

.helperPanel {
  position: relative;
}

.tryAgainButton {
  margin: 0;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 10;
  font-size: $normalSize;
  padding: $spacingXXS;
  width: 250px;
  height: 35px;
}
