$black: #222222;
$darkgrey: #555555;
$grey: #919191;
$lightgrey: #e1e1e1;
$brightgrey: #f1f1f1;
$yellow: #ffcb39;
$white: #ffffff;
$crusta: #ff8833;
$maroon: #85471b;
$sushi: rgb(115, 177, 65);
$equator: #e2ab5b;
$purple: #5a3264;
$froly: #f16868;
$sky: #8cb5ed;
$cornflower: #5d97ed;
$lightblue: #87ceeb;
$midnightblue: rgb(2, 116, 237);
$midnightdarken: rgb(1, 58, 119);
$darkblue: #012b57;
//$darkblue: #0e067b;
