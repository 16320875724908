@import '../../core/scss/base/index';

.home {
  height: 100%;
  overflow: hidden;
  background-size: cover;
}

.colWrapper {
  text-align: center;
}

.rowWrapper {
  height: 100%;
}

.title {
  display: inline-block;
  padding-left: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: $spacingM;
  font-size: 2 * $extraLargeSize;
  letter-spacing: 0.5rem;
  margin: 0;
  line-height: 3 * $extraLargeSize;
  color: $white;
  text-shadow: 0 0 30px;
  white-space: nowrap;
}
