@import '../../core/scss/base/index';

.login {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  label {
    color: $white;
  }
}

.wrapper {
  height: 100%;
}
