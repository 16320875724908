@import './base/index';
@import './tools/index';
@import './fonts/bang-whack-pow';
@import './fonts/roboto';

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  color: $darkgrey;
  font-size: $normalSize;
  background: $white;
  font-family: 'bangwhackpow', 'Helvetica', 'Arial', sans-serif;
  letter-spacing: 0.15em;
  overflow-y: auto;
  text-transform: uppercase;

  * {
    user-select: none;
  }
}

.scroll-container {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.tox.tox-tinymce {
  border: 1px solid $cornflower;
}

image {
  width: 100%;
  height: auto;
}

@import './vendors/index';
