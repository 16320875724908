@import '../../../core/scss/base/index';

.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
  background-color: rgba($white, 0.8);
  border-bottom: 1px solid $sky;
}

.logo {
  width: auto;
  height: 30px;
}

.item {
  color: $black;
  margin-right: $spacingM;
  text-decoration: none;
  font-size: $smallSize;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  span {
    margin-left: $spacingXS;
  }
}

.selected {
  text-decoration: underline;
  cursor: default;
  pointer-events: none;
}
