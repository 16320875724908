@import '../../../core/scss/base/index';

.skeleton {
  width: 100%;
  background: rgba($cornflower, 0.2);
  z-index: 0;
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    top: 0;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    animation: slide 2s linear infinite;
    animation-name: slide;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    /* 
    CSS Gradient - complete browser support from http://www.colorzilla.com/gradient-editor/ 
    */
    background: -moz-linear-gradient(
      left,
      rgba($midnightblue, 0) 0%,
      rgba($midnightblue, 0.8) 50%,
      rgba($cornflower, 0) 99%,
      rgba($cornflower, 0) 100%
    ); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0%, rgba($midnightblue, 0)),
      color-stop(50%, rgba($midnightblue, 0.8)),
      color-stop(99%, rgba($cornflower, 0)),
      color-stop(100%, rgba(125, 185, 232, 0))
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
      left,
      rgba($midnightblue, 0) 0%,
      rgba($midnightblue, 0.8) 50%,
      rgba($cornflower, 0) 99%,
      rgba($cornflower, 0) 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(
      left,
      rgba($midnightblue, 0) 0%,
      rgba($midnightblue, 0.8) 50%,
      rgba($cornflower, 0) 99%,
      rgba($cornflower, 0) 100%
    ); /* Opera 11.10+ */
    background: -ms-linear-gradient(
      left,
      rgba($midnightblue, 0) 0%,
      rgba($midnightblue, 0.8) 50%,
      rgba($cornflower, 0) 99%,
      rgba($cornflower, 0) 100%
    ); /* IE10+ */
    background: linear-gradient(
      to right,
      rgba($midnightblue, 0) 0%,
      rgba($midnightblue, 0.8) 50%,
      rgba($cornflower, 0) 99%,
      rgba($cornflower, 0) 100%
    ); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
  }
}

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.skeletonMessage {
  background-color: $midnightblue;
  position: relative;
  z-index: 2;
  font-size: $smallSize;
}
