@import '../../../../../../core/scss/base/index';

.wrapper {
  min-height: 400px;
  background-color: $midnightdarken;
  border: 1px solid $midnightblue;
}

.button {
  width: 280px;
}

.cancelButton {
  background-color: $froly;
  border-color: $froly;
}

.image {
  width: 100px;
  height: auto;
}
