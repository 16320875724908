@import '../../../core/scss/base/index';

.carousel {
  background: $white;
  text-align: center;
  width: 100%;
  height: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.arrowLeft,
.arrowRight {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: $largeSize;
  border: 1px solid $darkblue;
  color: $darkblue;
  border-radius: 50%;
  padding: $spacingS;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  background: $white;

  &:hover {
    background: $cornflower;
    color: $white;
  }
}

.disabled {
  color: $lightgrey;
  border-color: $lightgrey;
  pointer-events: none;

  &:hover {
    background: #f4f4f4;
  }
}

.arrowLeft {
  left: $spacingL;
}

.arrowRight {
  right: $spacingL;
}

.slides {
  left: 0;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  list-style: none;
  position: relative;
  // -webkit-transition: transform 0.5s;
  // -moz-transition: transform 0.5s;
  // -ms-transition: transform 0.5s;
  // -o-transition: transform 0.5s;
  // transition: transform 0.5s;
  height: 100%;
}

.slide {
  position: relative;
  float: left;
  height: 100%;
  pointer-events: none;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.navigation {
  display: block;
  list-style: none;
  text-align: center;
  bottom: 0.5em;
  position: absolute;
  width: 100%;
}

.bullet {
  margin: 6px;
  display: inline-block;
  height: 10px;
  width: 10px;
  border: solid 1px $midnightblue;
  font-size: 0;
  cursor: pointer;

  &:hover {
    border-color: $crusta;
  }
}

.selected {
  background: rgba(1, 43, 87, 0.3);
}
