@import '../../../core/scss/base/index';

.error {
  text-align: left;
  margin-bottom: $spacingXS;
  color: $froly;
  font-size: $largeSize;
  letter-spacing: 0.2em;
  font-weight: 300;
  border: 1px solid $froly;
  padding: $spacingS $spacingM;
  width: 100%;
  opacity: 0.8;
  span {
    margin-left: $spacingS;
  }
}
