@import '../../../../core/scss/base/index';

.exercise {
  padding-bottom: $spacingS;
  margin-bottom: $spacingS;
  border-bottom: 1px solid $brightgrey;

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
}