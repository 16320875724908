@import '../../core/scss/base/index';

.board {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  overflow: hidden;
}

.loadingScreen {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: $white;
}

.stats {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: $white;
  border-top: 1px solid $grey;
  pointer-events: initial;
}

.item {
  span {
    color: $froly;
    font-size: $extraLargeSize;
    margin-right: $spacingXS;
  }

  svg {
    color: $froly;
  }
}

.enemy {
  span {
    color: $midnightblue;
    font-size: $extraLargeSize;
    margin-right: $spacingXS;
  }
  img {
    width: 60px;
    height: auto;
  }
}
