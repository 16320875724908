@import '../../core/scss/base/index';

.wrapper {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;
  font-size: $largeSize;
}

.card {
  background-color: rgba($cornflower, 0.1);
}

.svg {
  width: 200px;
  height: 200px;
  margin-bottom: $spacingL;
  fill: $white;
}
