@import '../../../core/scss/base/index';

.error {
  text-align: left;
  margin-bottom: $spacingXS;
  color: $froly;
  font-size: $normalSize;
  letter-spacing: 0.2em;
  font-weight: 300;
}
