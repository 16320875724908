@import '../../../../core/scss/base/index';

.modal {
  background-color: $white;
  pointer-events: initial;
  border-radius: 4px;
  width: 700px;
}

.title {
  margin-top: $spacingS;
  font-size: $largeSize;
  color: $sushi;
}

.completeButton {
  width: 300px;
}
