@import '../../../core/scss/base/index';

.timer {
  color: $cornflower;
}

.passedTime {
  color: $white;
  font-size: $largeSize;
}
