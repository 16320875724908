@import '../../core/scss/base/index';

.exerciseEdit {
  min-height: 100%;
  font-family: 'robotolight';
  text-transform: initial;
}

.input input {
  font-size: $normalSize;
}

.imageWrapper {
  border: 1px solid $cornflower;
  position: relative;
}

.removeImage {
  position: absolute;
  top: $spacingXXS;
  right: $spacingXXS;
  color: $froly;
  cursor: pointer;

  &:hover {
    color: $darkblue;
  }
}

.label {
  font-size: $smallSize;
  margin-bottom: $spacingXXS;
  display: block;
}

.redirectButton {
  font-size: $smallSize;
  color: $cornflower;
  padding: $spacingXXS;
  border: 1px solid $cornflower;
  text-decoration: none;

  span {
    margin-left: $spacingXXS;
  }

  &:hover {
    background-color: rgba($cornflower, 0.1);
  }
}
