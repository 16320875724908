@import '../../../core/scss/base/index';

.menu {
  background-color: rgba($midnightblue, 0.1);
  position: sticky;
  top: 0;
  z-index: 2;
}

.logo {
  height: 50px;
  width: auto;
}

.item {
  font-size: $largeSize;
  color: $white;
  letter-spacing: 0.1em;
  text-decoration: none;
  margin-right: $spacingL;

  &:hover {
    color: $white;
    text-decoration: underline;
  }
}

.language {
  color: $white;
  font-size: $largeSize;

  select {
    margin-left: $spacingXS;
    padding: $spacingXXS;
    border-radius: none;
    border: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}
