@import '../../../core/scss/base/index';

.checkbox {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 100%;

  &:hover .checkboxContainer {
    color: $cornflower;
    border: 4px solid $cornflower;
  }
}

.checkboxContainer {
  width: 100%;
  min-height: 60px;
  color: $darkgrey;
  transition: color 0.175s cubic-bezier(0.4, 0, 1, 1);
  pointer-events: none;
  font-size: $extraLargeSize;
  border: 4px solid $darkgrey;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacingXXS $spacingS;

  img {
    width: 100%;
    height: auto;
  }
}

.text {
  font-size: $normalSize;
}

.input {
  outline: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-width: 0;
  margin: 0;
  padding: 0;
  box-shadow: none;

  &:focus {
    border-width: 0;
    box-shadow: none;
  }

  &:hover {
    cursor: pointer;
  }
}

.checked .checkboxContainer {
  text-shadow: none;
  color: $grey;
  border-color: $grey;
}

.selected .checkboxContainer {
  text-shadow: none;
  color: $lightgrey;
  border-color: $lightgrey;
}

.disabled .checkboxContainer {
  color: $black;
  text-shadow: -1px -1px 0 $grey, 1px -1px 0 $grey, -1px 1px 0 $grey,
    1px 1px 0 $grey;
}

.valid .checkboxContainer {
  text-shadow: none;
  color: $sushi;
  border-color: $sushi;
}

.invalid .checkboxContainer {
  text-shadow: none;
  color: $froly;
  border-color: $froly;
}
