@import '../../../../core/scss/base/index';

.instructions {
  position: relative;
}

.button {
  width: 40px;
  height: 40px;
  background-color: $white;
  cursor: pointer;
  color: $grey;

  &:hover {
    color: $black;
  }
}

.panel {
  width: 50vw;
  height: 600px;

  position: absolute;
  bottom: 50px;
  left: 0;
  background-color: $white;
  border: 1px solid $grey;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.closedPanel {
  bottom: -2000px;
}

.wrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.closeButton {
  position: absolute;
  right: $spacingXS;
  top: $spacingXS;
  cursor: pointer;
  color: $grey;
  z-index: 1;

  &:hover {
    color: $black;
  }
}

.title {
  margin-top: $spacingS;
  font-size: $largeSize;
}

.key {
  width: 30px;
  height: 30px;
  border: 1px solid $grey;
  border-bottom-width: 2px;
  border-radius: 4px;
  font-family: 'RobotoRegular';
  font-size: $extraSmallSize;
  text-transform: lowercase;
  letter-spacing: 0.1em;
}

.eKey {
  text-transform: uppercase;
}

.spaceKey {
  border: 1px solid $grey;
  border-bottom-width: 2px;
  height: 16px;
  width: 50%;
}

.shiftKey {
  transform: rotate(180deg) translate(-30%, -30%);
}

.shard {
  color: $froly;
}

.mouseKey {
  height: 40px;
  width: 24px;
  border: 1px solid $grey;
  border-radius: 40%;
  overflow: hidden;

  div {
    width: 50%;
    height: 16px;
    border-bottom: 1px solid $grey;
    &:first-child {
      background-color: $grey;
    }
  }
}

.enemy {
  width: 50px;
  height: auto;
}
