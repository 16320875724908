@import '../../../core/scss/base/index';

.dropdownWrapper {
  width: 100%;
  position: relative;
}

.currentItem {
  border: 1px solid $cornflower;
  padding: $spacingXXS;
  cursor: pointer;
  background: $white;

  img {
    height: 40px;
    width: auto;
  }

  span {
    margin-left: $spacingS;
  }
}

.placeholder {
  color: $lightgrey;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item {
  padding: $spacingXXS;
  font-size: $normalSize;
  cursor: pointer;
  border-bottom: 1px solid $sky;

  img {
    height: 30px;
    width: auto;
  }

  span {
    margin-left: $spacingS;
  }

  &:hover {
    background-color: rgba($cornflower, 0.1);
  }
}

.selected {
  background-color: rgba($cornflower, 0.1);
}

.text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown {
  width: 100%;
  position: absolute;
  top: auto;
  left: 0;
  top: auto;
  border: 1px solid $cornflower;
  background: $white;
  z-index: 2;
  max-height: 500px;
  overflow-y: auto;
}
