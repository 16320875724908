@import '../../../../../../core/scss/base/index';

.zone {
  width: 60px;
}

.zoneName {
  text-align: center;
  font-size: $normalSize;
}

.zoneBlock {
  height: 60px;
  background-color: rgba($grey, 0.2);
  color: $grey;
  border-bottom: 1px solid $grey;
}

.zonePassed {
  background-color: rgba($sushi, 0.2);
  color: $sushi;
  border-bottom-color: $sushi;
}

.zoneCurrent {
  background-color: rgba($cornflower, 0.2);
  color: $cornflower;
  border-bottom-color: $cornflower;
}

.errorMessage {
  background-color: $black;
  height: 300px;
}
