@import '../../../core/scss/base/index';

.button {
  color: $white;
  border: 1px solid $midnightblue;
  padding: $spacingS;
  text-decoration: none;
  font-size: $largeSize;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  text-shadow: 0 0 10px;
  background: $midnightblue;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 60px;
  margin: $spacingM auto;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: $white;
    box-shadow: inset 0 0 25px -10px $black;
  }

  &:focus,
  &:active {
    outline: none;
    border: 1px solid $cornflower;
  }

  &:disabled {
    background: $grey;
    border-color: $darkgrey;
    color: $darkgrey;
    cursor: not-allowed;
  }

  span {
    margin-left: $spacingS;
    display: inline-block;
    vertical-align: top;
  }
}
