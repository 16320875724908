@import '../../../core/scss/base/index';

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}

.button {
  position: relative;
  border: 1px solid $cornflower;
  background-color: $white;
  color: $midnightblue;
  font-size: $normalSize;
  padding: $spacingXS $spacingS;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  cursor: pointer;
  min-width: 250px;
  width: 100%;

  &:hover {
    background-color: rgba($cornflower, 0.1);
  }

  &:focus {
    outline: none;
  }

  span {
    margin-left: $spacingXXS;
  }
}

.disabled {
  cursor: not-allowed;
  color: $grey;
  border-color: $grey;

  &:hover {
    color: $grey;
    background-color: $white;
  }

  &:hover:not(.loading) {
    animation: shake 0.82s 0.4s cubic-bezier(0.36, 0.7, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
}

.loading {
  cursor: wait;
  text-indent: -9999px;

  &::after {
    opacity: 1;
    position: absolute;
    left: calc(50% - ((30px + 2px) / 2));
    top: calc(50% - ((30px + 2px) / 2));
    height: calc(30px + 2px);
    width: calc(30px + 2px);
    animation: rotate360 0.5s infinite linear;
    border-radius: 50%;
    border: 1px solid transparent;
    border-bottom-color: $cornflower;
    border-left-color: $cornflower;
    box-sizing: border-box;
    content: '';
    display: block;
  }
}
