@import '../../core/scss/base/index';

.exercises {
  min-height: 100%;
  font-family: 'robotolight';
  text-transform: initial;
}

.list {
  width: 100%;
}

.item {
  padding: $spacingXS;
  border-bottom: 1px solid $lightgrey;
  color: $black;
  font-family: 'robotolight';

  &:first-child {
    position: sticky;
    top: 63px;
    color: $grey;
    background-color: $brightgrey;
    font-family: 'robotoregular';
    z-index: 1;
  }
}

.label {
  font-size: $smallSize;
  font-family: 'robotothin';
}

.actions {
  text-decoration: none;
  color: $darkgrey;

  &:hover {
    text-decoration: underline;
  }

  span {
    margin-left: $spacingXS;
  }
}
