@import '../../core/scss/base/index';

.setup {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: auto;
}

.label {
  color: $white;
}

.card {
  border: 2px solid $darkblue;
  cursor: pointer;

  &:hover {
    border: 2px solid $cornflower;
  }
}

.selected {
  border: 2px solid $white;
}
