@import '../../../core/scss/base/index';

.helper {
  width: 400px;
  height: 400px;
  position: relative;
  border: 1px solid $cornflower;
  background-color: $white;
  margin: 0 auto;
}

.overlay,
.buttonOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.overlay {
  background-color: $cornflower;
  color: $sky;
  svg {
    width: 100px;
    height: 100px;
  }
}

.buttonOverlay {
  background-color: rgba($cornflower, 0.3);
}

.refresh,
.play {
  width: 40px;
  height: 40px;
  border: 1px solid $cornflower;
  background-color: $white;
  color: $cornflower;
  z-index: 2;
  cursor: pointer;

  &:hover {
    background-color: $sky;
    color: $white;
  }
}
