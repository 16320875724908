@import '../../../core/scss/base/index';

.outer {
  display: flex;
  align-items: center;
  height: 20px;
  width: 300px;
  background: $cornflower;
}

.inner {
  height: 20px;
  width: 0;
  background: $darkblue;
  transition: width 100ms ease-out;
}