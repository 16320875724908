@import '../../core/scss/base/index';

.profile {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: auto;
  color: $white;
}

.card {
  background-color: rgba($cornflower, 0.1);
}

.innerCard {
  border: 1px solid $cornflower;
}

.tabWrapper {
  border-bottom: 1px solid $cornflower;
}

.tab {
  color: $midnightblue;
  cursor: pointer;
  border-bottom: 4px solid transparent;

  &:hover {
    color: $cornflower;
    background-color: rgba($cornflower, 0.1);
  }

  span {
    margin-left: $spacingXS;
  }
}

.cardLabel {
  text-align: center;
  padding: $spacingXS 0;
  background-color: $midnightblue;
  margin-top: $spacingXS;
}

.tabSelected {
  color: $white;
  border-bottom: 4px solid $white;
}

.tabCard {
  width: 100%;
  background-color: rgba($cornflower, 0.1);
  min-height: 200px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: saturate(0%);
  }
}

.label {
  span {
    color: $yellow;
  }
}

.description {
  color: $yellow;
}

.missionButton {
  margin: 0;
}

.gold {
  color: $froly;
  font-size: $largeSize;

  div {
    font-size: $normalSize;
    color: $white;
    margin-right: $spacingXXS;
  }

  span {
    margin-left: $spacingXXS;
  }
}

.tabCardActive img {
  filter: saturate(100%);
}

.tabCardName {
  text-align: center;
}

.tabCardClickable {
  cursor: pointer;
}

.tabCardPrice {
  color: $froly;
  width: 100%;

  span {
    margin-left: $spacingXXS;
  }

  button {
    border: 1px solid transparent;
    border-radius: none;
    background-color: $yellow;
    padding: $spacingXXS $spacingXS;
    letter-spacing: 0.1em;
    color: $maroon;
    cursor: pointer;

    &:hover {
      background-color: $crusta;
      color: $white;
    }

    &:focus {
      outline: none;
    }

    &:active {
      border: 1px solid $yellow;
    }
  }
}

.tabCardSelected {
  border: 1px solid $cornflower;
}
