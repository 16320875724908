@import '../../../core/scss/base/index';

.progress {
  background-color: transparent;
  width: 100%;
}

.progressItem {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: nowrap;
  width: 100%;
  padding: 0;
  position: relative;

  &:first-child {
    width: auto;

    &:before {
      display: none;
    }

    span {
      width: 40px;
    }
  }

  span {
    border-radius: 100px;
    height: 30px;
    width: 100%;
    max-width: 30px;
    background: rgba($grey, 0.2);
    color: $grey;
    position: relative;
    font-size: 2rem;
    z-index: 2;
    transition: all 0.45s;
    border: 1px solid $grey;
  }

  &:before {
    content: '';
    height: 1px;
    background: $grey;
    width: 100%;
    display: block;
  }
}

.active {
  &:before {
    background: $cornflower;
  }

  span {
    background: $cornflower;
    border: 1px solid $cornflower;
    transition-delay: 0.45s;
  }
}

.visited {
  &:before {
    background: $cornflower;
  }

  span {
    background: rgba($cornflower, 0.2);
    transition-delay: 0.45s;
    color: $cornflower;
    border: 1px solid $cornflower;
  }
}

.invalid {
  &:before {
    background: $froly;
  }
  span {
    background: rgba($froly, 0.2);
    transition-delay: 0.45s;
    color: $froly;
    border: 1px solid $froly;
  }
}

.valid {
  &:before {
    background: $sushi;
  }
  span {
    background: rgba($sushi, 0.2);
    transition-delay: 0.45s;
    color: $sushi;
    border: 1px solid $sushi;
  }
}
