@import '../../../core/scss/base/index';

.media {
  cursor: pointer;
  border: 1px solid $cornflower;
  width: 100%;
  height: 300px;
  position: relative;
}

.refreshButton {
  width: 250px;
  margin-bottom: 16px;
  font-size: 12px;
}

.mediaImage {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: contain;
}

.placeholder {
  background-color: rgba($cornflower, 0.2);
  color: $cornflower;
  font-size: $largeSize;
  text-transform: uppercase;
  text-align: center;
}

.previewLabel {
  word-wrap: break-word;
  color: $cornflower;
}

.mediaSmall {
  height: 50px;
  font-size: $smallSize;
}

.mediaMedium {
  height: 150px;
  font-size: $normalSize;
}

.mediaLarge {
  height: 300px;
  font-size: $largeSize;
}
