@import '../../../core/scss/base/index';

.modalWrapper {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.modalBody {
  height: 100%;
  overflow-y: auto;
}

.modalBodyWrapper {
  height: calc(100% - 60px);
}

.modalFooter {
  background-color: $brightgrey;
  button {
    max-width: 250px;
  }
}

.modalWrapperVisible {
  display: flex;
}

.modalContainer {
  background-color: white;
  width: 80%;
  height: 80%;
}

.modalHeader {
  background-color: $cornflower;
  color: $white;
  height: 60px;
}

.closeButton {
  cursor: pointer;
}
