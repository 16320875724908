@import '../../../../../../core/scss/base/index';

.missionComplete {
  min-height: 400px;
  background-color: $black;
}

.button {
  width: 380px;
}

.cancelButton {
  background-color: $froly;
  border-color: $froly;
}
