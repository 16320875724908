@import '../../../core/scss/base/index';

$offset: 187;
$duration: 1.4s;

@keyframes colors {
  0% {
    stroke: #4285f4;
  }
  25% {
    stroke: #de3e35;
  }
  50% {
    stroke: #f7c223;
  }
  75% {
    stroke: #1b9a59;
  }
  100% {
    stroke: #4285f4;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: $offset/4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}

.loading {
  width: 100%;
  height: 100%;

  svg {
    animation: rotator $duration linear infinite;
    animation-name: dash;
    animation-duration: $duration;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  circle {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation-name: dash, colors;
    animation-duration: $duration, ($duration * 4);
    animation-iteration-count: infinite, infinite;
    animation-timing-function: ease-in-out, ease-in-out;
  }

  span {
    margin-left: $spacingS;
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
