@import '../../core/scss/base/index';

.reports {
  min-height: 100%;
  font-family: 'robotolight';
  text-transform: initial;
}

.list {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  padding-right: $spacingS;
  border: 1px solid $lightgrey;
}

.wrapper {
  position: relative;
  width: 100%;
}

.csv {
  button {
    font-size: $extraSmallSize;
    margin-bottom: 0 !important;
  }
}

.cleanButton {
  font-size: $extraSmallSize;
  margin-bottom: 0 !important;
  margin-left: $spacingS;
}

.userDropdown {
  width: 350px;
  margin-left: $spacingS;
}

.cleanUserButton {
  margin-left: $spacingS;
  min-width: 36px !important;
  height: 36px;
  width: 36px;
  padding: $spacingXXS;
}

.item {
  position: absolute;
  top: 0;
  left: 0;
  height: 200px;
  width: 100%;
  padding: $spacingS;
  border: 1px solid $lightgrey;
  color: $darkgrey;
  font-size: $smallSize;
}

.itemLabel {
  font-family: sans-serif;
  font-weight: bold;
}

.itemValue {
  font-family: sans-serif;
}