@import '../../../core/scss/base/index';

.treeNode {
  display: flex;
  align-items: center;
  padding: 5px 8px;
  cursor: pointer;
  &:hover {
    background: rgba($cornflower, 0.2);
  }
}

.nodeLabel {
  color: $black;
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nodeList {
  display: none;
}

.nodeListVisible {
  display: block;
}

.audio {
  color: $sushi;
}

.image {
  color: $cornflower;
}
