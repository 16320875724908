@font-face {
  font-family: 'robotobold';
  src: url('./roboto/Roboto-Bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotolight';
  src: url('./roboto/Roboto-Light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotomedium';
  src: url('./roboto/Roboto-Medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotoregular';
  src: url('./roboto/Roboto-Regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotothin';
  src: url('./roboto/Roboto-Thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
