@import '../../core/scss/base/index';

.missions {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  color: $white;
  position: relative;
}
