@import '../../../../../../core/scss/base/index';

.finalMission {
  min-height: 400px;
  background-color: $midnightdarken;
  border: 1px solid $midnightblue;
  position: absolute;
  top: $spacingL;
  left: $spacingL;
  z-index: 100;
  color: $froly;
}

.button {
  width: 350px;
  background-color: $froly;
  border-color: $froly;
}

.image {
  width: 100px;
  height: auto;
  border: 2px solid $froly;
  border-radius: 50%;
}
