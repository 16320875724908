@import '../../../../../../core/scss/base/index';

.missingPack {
  min-height: 400px;
  background-color: $black;
}

.button {
  width: 380px;
}

.continueButton {
  background-color: $froly;
  border-color: $froly;
}
