@import '../../../core/scss/base/index';

.wrapper {
  background-color: rgba($cornflower, 0.1);
}

.problem {
  font-size: $largeSize;
  border: 1px solid $cornflower;
  background-color: $white;
  font-family: sans-serif;
  text-transform: initial;
}

.options {
  border: 1px solid $cornflower;
  background-color: $white;

  img {
    background-color: $white;
  }
}

.optionWrapper {
  width: 100%;
}
