@import '../../../core/scss/base/index';

.header {
  background-color: $midnightdarken;
  color: $white;
  position: relative;
  z-index: $menuIndex;
}

.logo {
  height: 30px;
  width: auto;
}

.user span {
  color: $yellow;
}

.button {
  background-color: transparent;
  border: none;
  color: $froly;
  margin-left: $spacingXS;
  cursor: pointer;

  &:hover {
    color: $crusta;
  }

  span {
    margin-left: $spacingXXS;
  }
}
