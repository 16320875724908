@import '../../../../core/scss/base/index';

.missionsTitle {
  font-size: $largeSize;
  letter-spacing: 0.2em;
  text-align: center;
  color: $yellow;
}

.checkmark {
  color: $sushi;
}

.profileButton {
  width: 100%;
  margin: 0;
  font-size: $normalSize;
}

.mission {
  cursor: pointer;
  color: $white;
  border-right: 4px solid transparent;
  &:hover {
    background-color: rgba($cornflower, 0.2);
  }
}

.missionSelected {
  background-color: rgba($midnightblue, 0.2);
  border-right: 4px solid $cornflower;
  color: $crusta;
}

.missionDisabled {
  cursor: not-allowed;
  pointer-events: none;
  color: $grey;
}

.missionsBar {
  width: 200px;
  height: calc(100% - 60px);
  z-index: 2;
  position: absolute;
  left: 0;
  top: 60px;
  background-color: rgba($cornflower, 0.2);
  overflow-y: auto;
}

.missionImage {
  width: 40px;
  height: auto;
}
