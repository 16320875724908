@import '../../../core/scss/base/index';

.title {
  letter-spacing: 0.2em;
  font-weight: 100;
}

.expressive1 {
  font-size: $extraLargeSize;
}

.expressive2 {
  font-size: $largeSize;
}

.expressive3 {
  font-size: $normalSize;
}

.expressive4 {
  font-size: $smallSize;
}

.expressive5 {
  font-size: $smallSize;
}

.expressive6 {
  font-size: $smallSize;
}
