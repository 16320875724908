@import '../../../core/scss/base/index';

.gdpr {
  width: 100%;
  position: fixed;
  z-index: 1010;
  bottom: 0;
  left: 0;
  padding: $spacingM;
  background-color: rgba($darkblue, 0.8);
  font-size: $smallSize;
  color: $white;
}

.button {
  width: 100px;

  &:hover {
    background: rgba($white, 0.8);
  }
}

.textBlock {
  margin: 0;
  margin-right: $spacingS;
}
