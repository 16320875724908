@import '../../../core/scss/base/index';

.wrapper {
  width: 100%;
}

.label {
  display: block;
  margin-bottom: $spacingXXS;
  font-size: $smallSize;
}

.inputField {
  font-size: $largeSize;
  padding: $spacingXS;
  letter-spacing: 0.15em;
  border-radius: none;
  border: 1px solid $cornflower;
  background-color: $white;
  width: 100%;
  color: $black;
  margin-bottom: $spacingXS;

  &:-internal-autofill-selected {
    background-color: $lightgrey;
  }

  &:focus {
    outline: 4px solid $cornflower;
    background-color: $white;
  }
}

.valid {
  border-color: $sushi;
}

.invalid {
  border-color: $froly;
}

.disabled {
  border-color: $grey;
  color: $grey;
}
