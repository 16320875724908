@import '../../core/scss/base/index';

.team {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.wrapper {
  height: 100%;
  overflow-y: auto;
}

.member {
  & > span {
    margin-top: $spacingS;
    display: inline-block;
    border-radius: 0 16px 16px 0;
    font-size: $normalSize;
    color: $cornflower;
    letter-spacing: 0.1em;
  }

  img {
    width: 100%;
    max-width: 240px;
    border-radius: 50%;
    border: 15px solid transparent;
    box-shadow: 2px 2px 0px rgba(255, 255, 255, 0.18);
    transition: all 0.1s ease-in;
  }

  h4 {
    margin-top: -20px;
    font-size: $extraLargeSize;
    font-weight: 700;
    color: $white;
    opacity: 0.8;
    letter-spacing: 0.1em;
  }

  h4 span {
    display: block;
    position: relative;
    left: -40px;
    top: 10px;
    font-size: 20px;
    background: none;
    padding: $spacingXS 0px;
    color: $white;
    opacity: 0.8;
    letter-spacing: 0.1em;
  }
}

.title {
  font-size: $extraLargeSize;
  color: $cornflower;
  letter-spacing: 0.2em;
}

.description {
  font-size: $largeSize;
  color: rgba($white, 0.5);
  letter-spacing: 0.1em;
}

.menuItem {
  font-size: $largeSize;
  color: $white;
  letter-spacing: 0.1em;
  text-decoration: none;

  &:hover {
    color: $white;
    text-decoration: underline;
  }
}
